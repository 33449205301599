<template>
  <div
    v-if="item"
    class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-10 lg:pr-7"
    :class="item.isStyle"
  >
    <div
      class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
    >
      {{ textValue(item) }}
      <span class="font-regular text-xs md:text-sm">
        {{ item.unit ? `(${item.unit})` : "" }}</span
      >
    </div>
    <div
      v-for="(att, index) in item.value_attributes"
      :key="index"
      class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
    >
      {{ contentValue(item.type_attributes_input.name, att.value) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    format(price) {
      let price2 = null;
      if (price.split(",")) {
        price2 = price.split(",").join(".");
      } else {
        price2 = price;
      }
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(price2);
    },
    textValue(item) {
      let text = null;

      if (item.type && item.type == 2) {
        text = item.id_name;
      } else {
        text = item.name;
      }
      return text;
    },
    contentValue(type, value) {
      const me = this;
      let val = null;

      if (type == "Price") {
        val = me.format(value);
      } else if (type == "Radio") {
        val = value == "1" ? "Si" : "No";
      } else {
        val = value;
      }
      return val;
    },
  },
};
</script>

<style></style>
